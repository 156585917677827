import "./Home.css"
import home from './img/intro.png'
import vague1 from './img/vague1.png'
import vague3 from './img/vague3.png'
import fond from './img/video.png'
import test1 from './img/test1.png'
import test2 from './img/test2.png'
import fond2 from './img/Bas_de_pages.jpg'
import ButtonGlobal from "../../components/Buttons/ButtonGlobal/ButtonGlobal";
import ButtonOutline from "../../components/Buttons/ButtonOutline/ButtonOutline";
import Carousel from "../../components/Carousel/Carousel";
import React, {useEffect, useRef, useState} from "react";
import { Helmet } from 'react-helmet'
import vagueFooter from "./img/vagueFooter.png"

const Home = () => {

    const [showVideo, setShowVideo] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;
            const videoElement = document.querySelector(".home-video");
            const videoPosition = videoElement.offsetTop;

            if (scrollPosition > videoPosition - window.innerHeight / 2) {
                setShowVideo(true);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleInteraction = () => {
            if (videoRef.current) {
                videoRef.current.play().catch(error => {
                    // La lecture automatique a échoué, peut-être en raison des restrictions du navigateur.
                    console.error("Erreur de lecture automatique : ", error);
                });
            }
        };

        window.addEventListener("click", handleInteraction);

        return () => {
            window.removeEventListener("click", handleInteraction);
        };
    }, []);

    useEffect(() => {
        if (showVideo) {
            videoRef.current.currentTime = 7;
        }
    }, [showVideo]);


    return (
        <>
            <Helmet>
                <title>LightCity V3 | Accueil</title>
            </Helmet>
            <Carousel/>
            <div className="container">
                <img className="vague-large" src={vague1} alt=""/>
                <div className="home-intro">
                    <div className="home-intro-content">
                        <h1>LIGHTCITY V3</h1>
                        <p className="home-intro-content-textsecond">Sur LightCity, laisse libre cours à tes rêves et deviens qui tu veux : que ce soit un policier, un homme d’affaires, un gangster ou bien un criminel. Notre univers t’offre un environnement vivant et authentique, propice à des interactions passionnantes avec d'autres joueurs. Viens bâtir ton propre récit, le tout dans une immersion totale.</p>
                        <ButtonGlobal link="https://discord.gg/lightcityrp" top="40px" text="Discord"/>
                        <ButtonOutline link="http://57.128.4.144/mediawiki/index.php/R%C3%A9glement" text="Règlement"/>
                    </div>
                    <div className="home-intro-img">
                        <img src={home} alt=""/>
                    </div>
                    <img className="home-intro-test" src={vague3} alt=""/>
                </div>

                {showVideo ? (
                    <div className="home-video">
                        <video ref={videoRef} controls={true} width="auto" height="auto" autoPlay>
                            <source src={require('./video/video.mp4')} type="video/ogg"/>
                            Your browser does not support the video tag.
                        </video>
                        <img style={{ width: "100%", position: "absolute" }} src={test1} alt="" />
                    </div>
                ) : (
                    <div style={{backgroundImage: `url(${fond})`, backgroundSize: "cover", backgroundRepeat: 'no-repeat'}} className="home-video">
                        <img className="home-video-vague" src={test1} alt="" />
                    </div>
                )}

                <div style={{backgroundImage: `url(${fond2})`, backgroundSize: "100% 100%", backgroundRepeat: 'no-repeat', width: "100%"}} className="home-users">
                    <img className="home-users-vague" src={test2} alt=""/>
                    <img className="home-users-vague2" src={vagueFooter} alt=""/>
                </div>
            </div>
        </>
    )
}

export default Home