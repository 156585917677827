import './App.css';
import Navbar from "./components/Navbar/Navbar";
import Home from "./Page/Home/Home";
import Footer from "./components/Footer/Footer";
import { Routes, Route } from 'react-router-dom';
import Propos from "./Page/Propos/Propos";
import Stream from "./Page/Streams/Stream";
import ScrollToTop from "./service/Scrolltop";

function App() {
    return (
        <>
            <Navbar/>
            <ScrollToTop/>
            <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route path="/propos" element={<Propos/>}/>
                <Route path="/streams" element={<Stream/>}/>
            </Routes>
            <Footer/>
        </>
    );
}

export default App;
