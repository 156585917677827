import "./Footer.css"
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <div className="footer">
                <div className="footer-content">
                    <h2>LIGHTCITY V3</h2>
                    <div className="footer-nav">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="https://discord.gg/lightcityrp" target="_blank" rel="noreferrer">Discord</a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link to="/propos">A propos</Link>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="http://57.128.4.144/mediawiki/index.php/R%C3%A9glement" target="_blank" rel="noreferrer">Règlement</a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link to="/streams">Streamers</Link>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="https://top-serveurs.net/gta/lightcity-rp" target="_blank" rel="noreferrer">Top serveurs</a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link to="/" >Accueil</Link>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                <p>&copy; 2023-2024 v3.lightcity.fr</p>
                <p>Developped by Warez</p>
            </div>
        </footer>
    )
}

export default Footer