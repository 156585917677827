import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import carousel1  from './img/carousel1.png'
import carousel2  from './img/carousel2.png'
import carousel3  from './img/carousel3.png'
import vague1 from "../../Page/Home/img/vague1.png";

const Carousel = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false, // Désactiver l'affichage des flèches de navigation,
        autoplay : true,
        autoplaySpeed: 5000,
        adaptiveHeight: true
    };

    return (
        <Slider {...settings}>
            <div>
                <img style={{backgroundSize: 'cover', width : "100%", height : "100vh"}} src={carousel3} alt=""/>
            </div>
            <div>
                <img style={{backgroundSize: 'cover', width : "100%", height : "100vh"}} src={carousel2} alt=""/>
            </div>
            <div>
                <img style={{backgroundSize: 'cover', width : "100%", height : "100vh"}} src={carousel1} alt=""/>
            </div>
        </Slider>
    );
};

export default Carousel;




