import {useEffect} from "react";
import fond from './img/propos.jpg'
import fond2 from './img/illegal.jpg'
import rectangle from './img/rectangle.png'
import vague6 from './img/vague6.png'
import ems from './img/Legals.jpg'
import lcpd from './img/LCPD.jpg'
import card1 from './img/card1.png'
import card2 from './img/card2.png'
import card3 from './img/card3.png'
import card11 from './img/cardill1.png'
import card12 from './img/cardill2.png'
import card13 from './img/cardill3.png'
import cardpol1 from './img/cardpol.png'
import cardpol2 from './img/cardpol2.png'
import cardpol3 from './img/cardpol3.png'
import cardgouv from './img/cardgouv.png'
import cardgouv2 from './img/cardgouv2.png'
import cardgouv3 from './img/cardgouv3.png'
import "./Propos.css"
import vector from './img/vector.png'
import vector2 from './img/vector2.png'
import vector3 from './img/vector3.png'
import vector4 from './img/vector4.png'
import vector5 from './img/vector5.png'
import vector6 from './img/vector6.png'
import vector9 from './img/vector9.png'
import logo18 from './img/logo18.png'
import {Helmet} from "react-helmet";
import gouv from './img/gouvernement.jpg'

const Propos = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    })

    return (
        <>
            <Helmet>
                <title>LightCity V3 | A Propos</title>
            </Helmet>
            <div style={{backgroundImage : `url(${fond})`, backgroundSize : "cover", backgroundRepeat : 'no-repeat'}} className="propos-intro">
                <div className="propros-intro-18">
                    <h1>
                        LIGHTCITY V3 <br/>

                    </h1>
                    <p>Votre aventure commence ici !</p>
                    <div className="propos-intro-logo">
                        <img className="logo18" width="40%" src={logo18} alt=""/>
                    </div>
                </div>
                <div style={{backgroundImage : `url(${rectangle})`, backgroundSize : "cover", backgroundRepeat : 'no-repeat'}} className="propos-intro-rectangle">
                    <p>Sur LightCity, laisse libre cours à tes rêves et deviens qui tu veux : que ce soit un policier, un homme d’affaires, un gangster ou bien un criminel. Notre univers t’offre un environnement vivant et authentique, propice à des interactions passionnantes avec d'autres joueurs. Viens bâtir ton propre récit, le tout dans une immersion totale.</p>
                </div>
                <img className="propos-intro-img" src={vague6} alt=""/>
            </div>

            <div style={{backgroundImage: `url(${ems})`, backgroundSize: "100% 100%", backgroundRepeat: 'no-repeat', width: "100%"}} className="propos-ems">
                <img width="100%" src={vector} alt=""/>
                <div className="propos-ems-intro">
                    <div className="propos-ems-intro-title">
                        <h2>
                            LÉGAL <br/>
                            Actif et diversifié
                        </h2>
                    </div>
                    <div className="propos-ems-flex">
                        <div style={{backgroundImage : `url(${card1})`, backgroundSize : "cover", backgroundRepeat : 'no-repeat'}} className="card-ems">
                            <div className="propos-ems-card-content">
                                <h2>Entreprises</h2>
                                <p>Tu peux créer ou reprendre une entreprise.Cela peut inclure un magasin, un restaurant, une entreprise de transport, de production et bien plus encore! Quelle sera la tienne ?</p>
                            </div>
                        </div>
                        <div style={{backgroundImage : `url(${card2})`, backgroundSize : "cover", backgroundRepeat : 'no-repeat'}} className="card-ems">
                            <div className="propos-ems-card-content">
                                <h2>Emplois</h2>
                                <p>Tu as la possibilité de travailler pour un patron, ou alors tu peux choisir de passer par des métiers intérimaires pour avoir moins de contraintes et ainsi gérer ton emploi du temps.</p>
                            </div>
                        </div>
                        <div style={{backgroundImage : `url(${card3})`, backgroundSize : "cover", backgroundRepeat : 'no-repeat'}} className="card-ems">
                            <div className="propos-ems-card-content">
                                <h2>Activités sociales</h2>
                                <p>Tu as envie de t’amuser en famille ou entre amis?
                                    On te propose plusieurs activités comme la fête foraine, le lasergame, les auto tamponneuses, du karting, du billard, de la pêche, de la chasse et bien plus encore !</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="propos-ems-vague" src={vector2} alt=""/>
            </div>

            <div style={{backgroundImage : `url(${lcpd})`, backgroundSize: "100% 100%", backgroundRepeat: 'no-repeat', width: "100%"}} className="propos-lcpd">
                <img width="100%" src={vector3} alt=""/>
                <div className="propos-lcpd-intro">
                    <div className="propos-ems-intro-title">
                        <h2>
                            LCPD
                        </h2>
                    </div>
                    <div className="propos-lcpd-flex">
                        <div style={{backgroundImage : `url(${cardpol1})`, backgroundSize : "cover", backgroundRepeat : 'no-repeat'}} id="cardlcpd" className="card-lcpd">
                            <div className="propos-lcpd-card-content">
                                <h2>LCPD</h2>
                                <p>LIGHT CITY POLICE DEPARTMENT est le service chargé de faire respecter la loi et l'ordre.
                                    Les membres du LCPD ont pour mission de protéger la ville contre toutes sortes d'injustices.
                                    Ils travaillent main dans la main avec différentes divisions,
                                    pour intervenir rapidement et efficacement en cas de situation de crise.
                                </p>
                            </div>
                        </div>
                        <div style={{backgroundImage : `url(${cardpol2})`, backgroundSize : "cover", backgroundRepeat : 'no-repeat'}} className="card-lcpd">
                            <div className="propos-lcpd-card-content">
                                <h2>Réalisme</h2>
                                <p>Le LCPD dispose de scripts qui permettent aux joueurs de vivre une expérience de jeu encore plus immersive et réaliste.
                                    Les membres de la police peuvent utiliser des brigades canines, examiner des scènes de crimes pour récolter des preuves et d'autres outils modernes
                                    de la police sont à disposition pour offrir une expérience de jeu unique et réaliste.</p>
                            </div>
                        </div>
                        <div style={{backgroundImage : `url(${cardpol3})`, backgroundSize : "cover", backgroundRepeat : 'no-repeat', height : "600px"}} className="card-lcpd">
                            <div className="propos-lcpd-card-content">
                                <h2>Spécialisations</h2>
                                <p>Plusieurs spécialisations sont disponibles au sein du LCPD avec des formations adaptées comme :
                                    <br /> - DOA
                                    <br /> - SWAT
                                    <br /> - MARSHAL
                                    <br /> - SHERIFF
                                    <br /> - RANGERS
                                    <br /> COAST-GUARD et bien plus ! <br />
                                    Rejoins-nous!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="propos-lcpd-vague" width="100%" src={vector4} alt=""/>
            </div>

            <div style={{backgroundImage : `url(${gouv})`, backgroundSize: "100% 100%", backgroundRepeat: 'no-repeat', width: "100%"}} className="propos-gouv">
                <img width="100%" src={vector5} alt=""/>
                <div className="propos-gouv-intro">
                    <div className="propos-ems-intro-title">
                        <h2>
                            GOUVERNEMENT
                        </h2>
                    </div>
                    <div className="propos-lcpd-flex">
                        <div style={{backgroundImage : `url(${cardgouv})`, backgroundSize : "cover", backgroundRepeat : 'no-repeat'}} id="cardgouv" className="card-lcpd">
                            <div className="propos-gouv-card-content">
                                <h2>Gouvernement</h2>
                                <p>Le gouvernement de LightCity joue un rôle primordial dans la gestion de la ville pour ses habitants.
                                    Il est composé de représentants élus et de fonctionnaires gouvernementaux.
                                    Il assure la prospérité de la ville, prend des décisions importantes en matière de politique, de loi et de gestion des services publiques.
                                </p>
                            </div>
                        </div>
                        <div style={{backgroundImage : `url(${cardgouv2})`, backgroundSize : "cover", backgroundRepeat : 'no-repeat'}} className="card-lcpd">
                            <div className="propos-gouv-card-content">
                                <h2>LCGS</h2>
                                <p>Le Light City Government Security est la branche de sécurité du gouvernement.
                                    Ce service se charge de protéger les infrastructures ainsi que les membres du gouvernement (extraction de VIP, transport etc...) .
                                    Ils sont formés pour gérer des situations difficiles à haut risque, sont équipés d'armes et de matériels spécialisés pour répondre à toutes situations.
                                </p>
                            </div>
                        </div>
                        <div style={{backgroundImage : `url(${cardgouv3})`, backgroundSize : "cover", backgroundRepeat : 'no-repeat'}}  id="cardgouv" className="card-lcpd">
                            <div className="propos-gouv-card-content">
                                <h2>FBI</h2>
                                <p>

                                    Le FBI est l’agence fédérale de LightCity chargée d'enquêter sur les crimes graves et les menaces envers la sécurité nationale.
                                    Nos agents sont hautement qualifiés et bénéficient d'une formation approfondie pour enquêter sur des affaires complexes.
                                    Tous les moyens sont mis en œuvre pour que leur travail d'enquête soit un atout majeur au sein de la ville.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="propos-gouv-vague" width="100%" src={vector6} alt=""/>
            </div>

            <div style={{backgroundImage : `url(${fond2})`, backgroundSize: "100% 100%", backgroundRepeat: 'no-repeat', width: "100%"}} className="propos-orga">
                <img width="100%" src={vector5} alt=""/>
                <div className="propos-orga-intro">
                    <div className="propos-ems-intro-title">
                        <h2>
                            ORGANISATIONS
                        </h2>
                    </div>
                    <div className="propos-orga-flex">
                        <div style={{backgroundImage : `url(${card11})`, backgroundSize : "cover", backgroundRepeat : 'no-repeat'}} id="cardillegal" className="card-orga">
                            <div className="propos-orga-card-content">
                                <h2>GROUPES ILLÉGAUX</h2>
                                <p>Light City t’offre la possibilité de créer ou rejoindre un groupe criminel tel que les indépendants, gangs, MC et organisations. Si ton groupe gagne en importance et en influence, il aura la possibilité d'évoluer sur la durée.
                                </p>
                            </div>
                        </div>
                        <div style={{backgroundImage : `url(${card12})`, backgroundSize : "cover", backgroundRepeat : 'no-repeat'}} id="cardillegal" className="card-orga">
                            <div className="propos-orga-card-content">
                                <h2>BUSINESS</h2>
                                <p>Sur LightCity, ou sur Cayo, plusieurs business sont disponibles comme:
                                    la création et gestion complète de réseaux de drogues, fabrication et revente d'armes, blanchiment d'argent etc...
                                </p>
                            </div>
                        </div>
                        <div style={{backgroundImage : `url(${card13})`, backgroundSize : "cover", backgroundRepeat : 'no-repeat'}} className="card-orga">
                            <div className="propos-orga-card-content">
                                <h2>ACTIVITÉS</h2>
                                <p>En étant dans l’illégal, de nombreuses activités s’offrent à toi, comme:
                                    Transgresser les zones protégées de chasse et pêche
                                    détourner des trains, braquer des supérettes, banques, entreprises et bien d’autres encore!!!
                                    les forces de l’ordre restent à l'affût, alors sois créatif si tu ne veux pas te retrouver en cellule!!!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="propos-gouv-vague" width="100%" src={vector9} alt=""/>
            </div>
        </>
    )
}

export default Propos