import vague from './img/vague.png'
import logo from './img/logo.png'
import "./Navbar.css"
import {Link} from "react-router-dom";

function Navbar () {
    return (
        <>
            <header className="header-navbar">
                <nav className="navbar mobile-navbar">
                    <div className="navbar-logo">
                        <img src={logo} alt="" />
                    </div>
                    <div className="navbar-items">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link to="/">Accueil</Link>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link to="/propos">A propos</Link>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="http://57.128.4.144/mediawiki/index.php/Réglement" target="_blank" rel="noreferrer">Règlement</a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link to="/streams">Streamers</Link>
                        <a href="https://discord.gg/lightcityrp" target="_blank" rel="noreferrer">Discord</a>
                        <div className="dropdown">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a href="#" className="dropbtn items">Nos sites</a>
                            <div className="dropdown-content">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a className="dropdown-link" href="https://v3.lightcity.fr/concess-light/">Concessionnaire</a>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a className="dropdown-link" href="https://v3.lightcity.fr/concess-paypal/">Catalogue Paypal</a>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a className="dropdown-link" href="https://v3.lightcity.fr/concess-bateau/">Catalogue Maritime</a>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a className="dropdown-link" href="https://v3.lightcity.fr/concess-helico/">Catalogue Aérien</a>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a className="dropdown-link" href="https://immo.aidenproject.fr/">Agence Immobilière</a>
                            </div>
                        </div>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="https://top-serveurs.net/gta/lightcity-rp" target="_blank" rel="noreferrer">Top serveurs</a>
                        <div className="hamburger">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </nav>
                <div className="navbar-vague mobile-vague">
                    <img src={vague} alt="" />
                </div>
            </header>
        </>
    )
}

export default Navbar