import fondStream from './img/twitch.png'
import vague1 from './img/vague1.png'
import vague2 from './img/vague2.png'
import vague3 from './img/vague3.png'
import streamer from './img/streamer.png'
import fondListe from './img/fond_list.png'
import "./streams.css"
import ButtonGlobal from "../../components/Buttons/ButtonGlobal/ButtonGlobal";
import {Helmet} from "react-helmet";

const Stream = () => {

    return (
        <>
            <Helmet>
                <title>LightCity V3 | Streamers</title>
            </Helmet>
            <div style={{backgroundImage : `url(${fondStream})`, backgroundSize: "100% 100%", backgroundRepeat: 'no-repeat', width: "100%"}} className="home-streamer">
                <img className="streamer-vague1" src={vague1} alt=""/>
            </div>

            <div className="streamer-background">
                <div className="home-first-streamer">
                    <div className="home-first-streamer-content">
                        <h1>
                            PAPS
                        </h1>
                        <span>Streameur Officiel</span>
                        <p>Yessss les amis !!!! Je suis un jeune streameur de 48ans et je vous dis si comme moi tu aimes t'amuser, partager sans prise de tête, tu es le bienvenu ici. En même temps, la vie a faite que je ne pouvais plus travailler donc, me voilà et j'ai bien l'intention de vous régaler !!!!!</p>
                        <ButtonGlobal link="https://kick.com/paps13" top="5%" text="Visionner"/>
                    </div>
                    <div style={{backgroundImage : `url(${streamer})`, backgroundSize : "cover", backgroundRepeat : 'no-repeat'}} className="home-first-streamer-img">
                        <iframe src="https://player.kick.com/paps13" height="400" width="700" allowFullScreen></iframe>
                    </div>
                </div>

                <div className="home-first-streamer">
                    <div className="home-first-streamer-content">
                        <h1>
                            AIDEN
                        </h1>
                        <span>Streameur Officiel</span>
                        <p>Bienvenue sur ma chaine. Jeune streamer dans la cinquantaine, vous y trouverez du gaming et rétrogaming, du roleplay mais aussi du développement sur FiveM. Alors n'attendez plus, venez me rejoindre !</p>
                        <ButtonGlobal link="https://kick.com/aidenproject-tv" top="5%" text="Visionner"/>
                    </div>
                    <div style={{backgroundImage : `url(${streamer})`, backgroundSize : "cover", backgroundRepeat : 'no-repeat'}} className="home-first-streamer-img">
                        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                        <iframe src="https://player.kick.com/aidenproject-tv" height="400" width="700" allowFullScreen></iframe>
                    </div>
                </div>

                <div className="home-first-streamer">
                    <div className="home-first-streamer-content">
                        <h1>
                            ZLAT
                        </h1>
                        <span>Streameur Officiel</span>
                        <p>Nouveau Streamer ! Ici pour prendre du bon temps. N'hésitez pas à follow pour me soutenir !</p>
                        <ButtonGlobal link="https://www.twitch.tv/lazlatfamilly" top="5%" text="Visionner"/>
                    </div>
                    <div style={{backgroundImage : `url(${streamer})`, backgroundSize : "cover", backgroundRepeat : 'no-repeat'}} className="home-first-streamer-img">
                        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                        <iframe
                            src="https://player.twitch.tv/?channel=lazlatfamilly&parent=v3.lightcity.fr&muted=true"
                            height="400"
                            width="700"
                            allowFullScreen>
                        </iframe>
                    </div>
                </div>

                <div className="home-first-streamer">
                    <div className="home-first-streamer-content">
                        <h1>
                            JIBARRA
                        </h1>
                        <span>Streameur Officiel</span>
                        <p>Bienvenue sur ma chaîne Kick, un lieu où l'aventure et le divertissement se rencontrent ! Rejoignez-moi pour des sessions de jeu épiques, des moments de rire et de complicité, et des discussions passionnantes avec une communauté animée. Que vous soyez un fan inconditionnel de jeux vidéo, un passionné de streaming ou simplement à la recherche d'un endroit où vous détendre et vous amuser, cette chaîne est faite pour vous. Préparez-vous à vivre des aventures incroyables .</p>
                        <ButtonGlobal link="https://kick.com/jibarra" top="5%" text="Visionner"/>
                    </div>
                    <div style={{backgroundImage : `url(${streamer})`, backgroundSize : "cover", backgroundRepeat : 'no-repeat'}} className="home-first-streamer-img">
                        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                        <iframe src="https://player.kick.com/jibarra" height="400" width="700" allowFullScreen></iframe>
                    </div>
                </div>


                <div style={{backgroundImage : `url(${fondListe})`, backgroundSize : "cover"}} className="home-list-streamer">
                    <img width="100%" src={vague2} alt=""/>

                    <div className="home-list-streamer-flex">

                        <div className="video-stream">
                            <h2>BigBoys</h2>
                            <iframe
                                src="https://player.twitch.tv/?channel=bigboys_fr&parent=v3.lightcity.fr&muted=true"
                                height="400"
                                width="700"
                                allowFullScreen>
                            </iframe>
                            <ButtonGlobal text="Visionner"/>
                        </div>

                        <div className="video-stream">
                            <h2>Reira Sama</h2>
                            <iframe
                                src="https://player.twitch.tv/?channel=reira5ama&parent=v3.lightcity.fr&muted=true"
                                height="400"
                                width="700"
                                allowFullScreen>
                            </iframe>
                            <ButtonGlobal text="Visionner"/>
                        </div>
                    </div>

                    <img className="vague-streamer" width="100%" src={vague3} alt=""/>
                </div>
            </div>
        </>
    )
}

export default Stream